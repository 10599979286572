import React, { useRef } from 'react';

import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';

import './contact.css';
import { Button } from 'primereact/button';
import emailjs from '@emailjs/browser';

class Contact extends React.Component {

    state = {
        nom: "",
        mail: "",
        phone: "",
        demand: ""
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    isEmail(str) {
        const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return re.test(str);
    }

    send = () => {
        //if(document.getElementById('sub-btn').disabled) {
        //    return;
        //}
        if( !this.state.nom || !this.state.mail || !this.state.demand || !this.state.phone ){
            this.toast.show({severity: 'error', summary: 'Erreur', detail: 'Veuillez remplir tout les champs'});
            return;
        } else if (!this.isEmail(this.state.mail)) {
            this.toast.show({severity: 'error', summary: 'Erreur', detail: 'Veuillez indiquer une addresse mail valide'});
            return;
        }else{
            
            this.toast.show({severity: 'success', summary: 'Succès', detail: 'Demande envoyée avec succès'});
            //document.getElementById('sub-btn').disabled = true;
            emailjs.send('service_7cuf8eb','template_f2i0299', { from_name: this.state.nom, from_email: this.state.mail, message: this.state.demand, phone: this.state.phone }, 'SQDcpfjBoAyr18Dsq')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
            }, (err) => {
                console.log('FAILED...', err);
            });
        }
    }

//[45.619880, 5.150330]
    render() {
        return (
            <div className="grid">
                <Toast ref={(el) => this.toast = el} />
                <div className="md:col-6 md:col-offset-1 col-12">
                    <div className="grid p-fluid">
                        <div className="col-12 md:col-6">
                            <span className="p-float-label">
                                <h5>Indiquez votre nom et prénom</h5>
                                <InputText className='browser-default' id="name" value={this.state.nom} onChange={(e) => this.setState({ nom: e.target.value })} />
                                
                            </span>
                        </div>

                        <div className="col-12 md:col-6">
                            <span className="p-float-label">
                                <h5>Indiquez votre email</h5>
                                <InputText className='browser-default' id="mail" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value })} />
                                
                            </span>
                        </div>

                        <div className="col-12 md:col-12">
                            <span className="p-float-label">
                                <h5>Indiquez votre numéro de téléphone</h5>
                                <InputText className='browser-default' id="phone" value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })} />
                                
                            </span>
                        </div>

                        <div className='col-12 md:col-12'>
                            <h5>Décrivez votre problème</h5>
                            <InputTextarea rows={5} cols={30} value={this.state.demand} onChange={(e) =>  this.setState({ demand: e.target.value })} autoResize />
                        </div>
                        <div className='col-6 col-offset-3'>
                            <Button id="sub-btn" label="Envoyer" className="p-button-warning" onClick={() => this.send()} />
                        </div>
                    </div>
                </div>
                <div className='md:col-4'>
                    <Card title="Contactez-nous" style={{ background: '#f9deae'}}>
                        <div className='grid' style={{ fontWeight: '600'}}>
                            <div className='col-1'>
                                <i className="material-icons">location_on</i>
                            </div>
                            <div className='col-11'>
                                Rhône Isère Assainissement<br/>
                                1105 route de Chapèze <br/>
                                38300 Saint-Savin<br/>
                                ---<br/>
                                25 rue André Chénier <br/>
                                69120 Vaulx en Velin<br/>  
                            </div>
                            <div className='col-1'>
                                <i className="material-icons">local_phone</i>
                            </div>
                            <div className='col-11'>
                            <a href="tel:0474802217" style={{color: "#f7931e" }}>04 74 80 22 17</a> <br/>
                            </div>
                            <div className='col-1'>
                                <i className="material-icons">phone_android</i>
                            </div>
                            <div className='col-11'>
                                <a href="tel:0626662794" style={{color: "#f7931e" }}>06 26 66 27 94</a> <br/>
                            </div>
                            <div className='col-1'>
                                <i className="material-icons">email</i>
                            </div>
                            <div className='col-11'>
                                <a style={{ color: '#c6312b'}} href="mailto:contact@ri-assainissement.fr">contact@ri-assainissement.com </a>
                            </div>
                        </div>
                        
                    </Card>
                    <Card title="Horaire">
                        <div className='grid' style={{ fontWeight: '600', fontSize: 'x-large'}}>
                            <div className='col-12' style={{ textAlign: 'center' }}>
                                Lundi - Samedi
                            </div>
                            <div className='col-12' style={{ textAlign: 'center' }}>
                                Service d'urgence <br/> 24h/24 7j/7
                            </div>
                        </div>
                    </Card>
                    <br/><br/>
                </div>
            </div>
        )
    }
}


export default Contact;