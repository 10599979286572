import React from "react";
import DualCard from "../dual-card/dualCard";
import nettoyage1 from './assets/nettoyage1.PNG';
import nettoyage2 from './assets/nettoyage2.PNG';
import nettoyage3 from './assets/nettoyage3.PNG';
import nettoyage4 from './assets/nettoyage4.PNG';
import nettoyage5 from './assets/nettoyage5.PNG';
import nettoyage6 from './assets/nettoyage6.PNG';
import nettoyage7 from './assets/nettoyage7.PNG';
import nettoyage8 from './assets/nettoyage8.PNG';


class Nettoyage extends React.Component {

    p1 = <p>
        Vous êtes une collectivité territoriale ?
        <br/><br/>
        Sachez qu’avant toute mise ou remise en service de canalisations, ces dernières doivent être obligatoirement nettoyées, rincées et désinfectées à très haute pression.
        <br/><br/>
        En fonction des types de locaux, cette opération garantit la décontamination et augmente la qualité de l’eau potable.
        <br/><br/>
        En outre, nous prenons en charge le pompage, la vidange des fosses septiques et le curage de canalisations.
        <br/><br/>
        Notre entreprise d’assainissement prend en charge également la désinfection et l’entretien de canalisations.
    </p>

    p2 = <p>
        Nous vous prodiguons un nettoyage haute pression, dédié à vos réseaux de canalisations industriels.
        <br/><br/>
        Notre nettoyage de canalisations empêche l’accumulation de micro-organismes, de parasites ou de substances constituant un danger potentiel pour la santé des personnes.
        <br/><br/>
        Il neutralise également les résidus ou les matières organiques susceptibles de dégrader la qualité de l’eau potable.
        <br/><br/>
        Vous possédez des cuves au sein de votre propriété ? Nous nettoyons également votre cuve à fioul.
    </p>

    p3 = <p>
        Nous favorisons le nettoyage des cuves à fioul puisqu’avec le temps, le stockage du fioul domestique crée un phénomène de condensation.
        <br/><br/>
        Il est préférable de procéder à un entretien régulier de votre cuve enterrée tous les 10 ans.
        <br/><br/>
        Spécialistes en la matière, nous mettons nos compétences en avant, afin de remédier à vos problèmes d’endommagement.
        <br/><br/>
        Afin d’éviter la dégradation précoce de votre matériel et de conserver un rendement calorifique optimal, nous vous invitons à nous contacter le plus tôt possible.
    </p>

    p4 = <p>
        Vous possédez des cuves à fioul ? Le nettoyage des cuves à fuel s’avère nécessaire pour augmenter la longévité de vos installations d’assainissement.
        <br/><br/>
        Un bon entretien doit être prodigué par des professionnels, et respecter les étapes de nettoyage, de pompage et de dégazage.
        <br/><br/>
        Nous dédions à votre cuve à fioul un nettoyage industriel de haut niveau, réalisé dans les délais convenus.
        <br/><br/>
        Faites appel à notre entreprise d’assainissement pour recevoir un devis gratuit et personnalisé.
    </p>

    p5 = <p>
        Grâce à nos camions de pompage performants, nous effectuons la vidange de la cuve à fuel en deux temps. Nous stockons dans la partie supérieure du réservoir le fioul qui peut être réutilisé.
        <br/><br/>
        Le reste du fioul est aussitôt pompé du fond de la cuve, afin de faire évacuer les sédiments et les résidus accumulés avec le temps.
        <br/><br/>
        Nous conseillons de ne pas réutiliser ce produit afin de ne pas encrasser la chaudière fioul.
        <br/><br/>
        Cependant, ce dernier est mis dans un autre compartiment du camion, en vue d’un recyclage ou d’une valorisation future.
    </p>

    p6 = <p>
        Nous réalisons le dégazage de cuves enterrées, afin de supprimer les vapeurs de fioul domestique suspendues à l’intérieur de la cuve.
        <br/><br/>
        Après le dégazage et le nettoyage de la cuve de stockage, nous intervenons auprès des particuliers pour la neutralisation complète de la cuve, en la remplissant d’un matériau inerte.
        <br/><br/>
        En plus de procéder au nettoyage complet des cuves en acier et dans le cadre du respect de l’environnement, nous intervenons dans le transport et traitement des déchets en toute sécurité.
    </p>

    p7 = <p>
        En fonction de la nature de vos déchets, nous effectuons la collecte, l’élimination et la valorisation.
        <br/><br/>
        Grâce à nos techniques respectueuses de l’environnement, après le nettoyage de votre cuve nous procédons à la collecte et au transport de plusieurs types de déchets.
        <br/><br/>
        Nous mettons un point d’honneur à respecter les normes d’hygiène et de sécurité environnementale.
        <br/><br/>
        Pour ce faire, nous déployons nos véhicules adaptés, nous permettant de transporter vos déchets en toute sécurité vers un centre de traitement agréé.
    </p>

    p8 = <p>
        Vous êtes des collectivités publiques ? Des professionnels ?
        <br/><br/>
        Souvent, les déchets industriels contiennent des produits chimiques ou des matières dangereuses.
        <br/><br/>
        Nous nous occupons du tri des produits recyclables et nous éliminons les déchets banals.
        <br/><br/>
        Pour veiller à la protection de l’environnement, nous neutralisons les déchets industriels dangereux et assurons leur transport dans un centre de traitement adapté.
    </p>

    componentDidMount() {
        window.scrollTo(0, 0);
    }


    render() {
        return(
            <div>
                <DualCard text={this.p1} image={nettoyage1} title="Pourquoi désinfecter les réseaux de canalisations ?" />
                <DualCard text={this.p2} image={nettoyage2} title="Nettoyage des réseaux industriels" order={1}/>
                <DualCard text={this.p3} image={nettoyage3} title="Nettoyage de cuve fioul" />
                <DualCard text={this.p4} image={nettoyage4} title="Curage de cuves à fioul" order={1}/>
                <DualCard text={this.p5} image={nettoyage5} title="Vidange de cuve fuel" />
                <DualCard text={this.p6} image={nettoyage6} title="Le dégazage et la neutralisation de cuve fioul et réglementation" order={1}/>
                <DualCard text={this.p7} image={nettoyage7} title="Récupération des déchets industriels" />
                <DualCard text={this.p8} image={nettoyage8} title="Transport de résidus hydrocarbures" order={1}/>

            </div>
        )
    }
};



export default Nettoyage;