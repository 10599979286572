import React from "react";
import DualCard from "../dual-card/dualCard";
import assainissement1 from './assets/assainissement1.PNG';
import assainissement2 from './assets/assainissement2.PNG';
import assainissement3 from './assets/assainissement3.PNG';
import assainissement4 from './assets/assainissement4.PNG';
import assainissement5 from './assets/assainissement5.PNG';
import assainissement6 from './assets/assainissement6.PNG';
import assainissement7 from './assets/assainissement7.PNG';
import assainissement8 from './assets/assainissement8.PNG';

class Assainissement extends React.Component {

    p1 = <p>
        Généralement constituée de deux cuves, la microstation d'épuration permet de traiter les eaux usées, afin de limiter la prolifération des bactéries.
        <br/><br/>
        Vous disposez d'une microstation d'épuration ? Vous souhaitez l'assainir ? La nettoyer ?
        <br/><br/>
        Bénéficiez de nos services d'assainissement de microstations d'épuration. Nous mettons à votre disposition nos matériels et nos outillages performants ainsi que nos techniciens experts en travaux d'assainissement.
        <br/><br/>
        Nous intervenons pour vous proposer des solutions de dégorgement pertinentes pour vos installations, tout en effectuant un diagnostic détaillé au préalable.
    </p>

    p2 = <p>
        En plus d'effectuer le pompage, la vidange ou le curage de votre réseau d'assainissement, nous réalisons également le nettoyage et le débouchage de vos canalisations.
        <br/><br/>
        Nos spécialistes du débouchage interviennent dans les plus brefs délais pour déboucher tout type de canalisations ou de bouchons (éviers, douches, toilettes bouchées…).
        <br/><br/>
        Grâce à nos services, vous pouvez ainsi garantir le bon fonctionnement de votre installation de tuyauterie.
        <br/><br/>
        Nous vous proposons également le débouchage de vos canalisations en utilisant une inspection vidéo.
    </p>

    p3 = <p>
        L'assainissement ou l'évacuation des eaux usées permet d'éviter le dégorgement des canalisations, et garantit le bon fonctionnement de vos réseaux d'assainissement.
        <br/><br/>
        Que ce soit pour un assainissement collectif ou un assainissement individuel, nous effectuons également la vidange de bac dégraisseur, l'installation de colonne immeuble et la vidange de fosse septique.
        <br/><br/>
        Nous sommes à votre disposition pour tous travaux de collecte des eaux usées, que ce soit pour les particuliers, pour les professionnels ou pour les collectivités.
        <br/><br/>
        Faites appel aux compétences de nos techniciens experts en assainissement, pour bénéficier d'une intervention rapide et soignée.
    </p>

    p4 = <p>
        La vidange de fosse septique permet de respecter les règles de mise en conformité et garantit la bonne évacuation des eaux usées au sein de votre habitation.
        <br/><br/>
        Elle est également utile pour améliorer la situation sanitaire globale de l'environnement et doit répondre à des règles bien précises. Notre entreprise agréée vous propose alors son expérience pour effectuer cette mission.
    </p>

    p5 = <p>
        L'assainissement non collectif grâce à une micro-station d'épuration par exemple, permet de devenir indépendant quant au traitement des eaux usées.
        <br/><br/>
        Pour des installations neuves comme pour des travaux de réhabilitation, la micro-station est la solution d'épuration idéale pour votre habitation familiale.
        <br/><br/>
        Le bon fonctionnement de la micro-station garantit l'épuration des eaux usées. Elle s'adapte en outre aux espaces réduits et préserve l'harmonisation paysagère de vos espaces verts.
        <br/><br/>
        Nous nous chargeons également d'entretenir tout système d'assainissement collectif qui répond à vos besoins.
    </p>

    p6 = <p>
        Notre entreprise certifiée, Rhône Isère Assainissement, est en mesure d'intervenir pour réaliser un diagnostic assainissement. Obligatoire lors d'une vente, celui-ci permettra au futur acquéreur d'être informé de l'état du système d'assainissement individuel du bien, avant la transaction immobilière. Nos professionnels de l'assainissement effectuent cette expertise avant la vente immobilière.
    </p>

    p7 = <p>
        Vous représentez un syndicat intercommunal ?
        <br/><br/>
        Concernant le règlement d’assainissement, les raccordements de vos immeubles aux égouts du domaine public sont une obligation applicable à l’ensemble des propriétaires résidant dans votre commune.
        <br/><br/>
        En effet, dans le but d’obtenir un assainissement collectif aux normes, les communes doivent suivre une réglementation bien précise, lors de l’élimination des boues d’épuration, les rejets des eaux usées, la collecte et le traitement biologique. Notre entreprise Rhône Isère Assainissement se tient à votre disposition afin que vous puissiez répondre à la réglementation.
    </p>

    p8 = <p>
        Dans une zone d’assainissement collectif, vos communes sont responsables d’assurer la collecte, le transport des eaux usées domestiques et l’élimination des boues d’épuration.
        <br/><br/>
        Vous devez également prendre en charge le stockage, l’épuration et la réutilisation des eaux ménagères, ainsi que le contrôle des raccordements au réseau public de collecte.
        <br/><br/>
        Dans ce cas de figure, les eaux usées collectées sont acheminées vers une station d’épuration de manière collective.
    </p>

    componentDidMount() {
        window.scrollTo(0, 0);
    }


    render() {
        return(
            <div>
                <DualCard text={this.p1} image={assainissement1} title="Nettoyage des microstations d'épuration" />
                <DualCard text={this.p2} image={assainissement2} title="Débouchage de canalisations" order={1}/>
                <DualCard text={this.p3} image={assainissement3} title="Traitement des eaux usées" />
                <DualCard text={this.p4} image={assainissement4} title="Vidange et nettoyage de fosses septiques" order={1}/>
                <DualCard text={this.p5} image={assainissement5} title="Pourquoi opter pour un assainissement non-collectif ?" />
                <DualCard text={this.p6} image={assainissement6} title="Diagnostic assainissement" order={1}/>
                <DualCard text={this.p7} image={assainissement7} title="Règlementation des réseaux d’assainissement collectif" />
                <DualCard text={this.p8} image={assainissement8} title="Collecte, traitement et transport des eaux usées" order={1}/>
            </div>
        )
    }
};



export default Assainissement;