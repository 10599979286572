import React from "react";
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';

import './dualCard.css';


class DualCard extends React.Component {

    render() {
        switch(this.props.order) {
            case 1:
                return(
                    <div>
                        <div class="grid lgs flex align-items-center justify-content-center fadeinleft animation-duration-1000">
                            <div class="sm:col-12 md:col-6" style={{ textAlign: "center" }}>
                                <div class="col-10 col-offset-2">
                                    <Image src={ this.props.image } alt="illustration" preview className="imgCards" />
                                </div>
                                
                            </div>
                            <div class="sm:col-12 md:col-6 lgs" style={{ textAlign: "center" }}>
                                <div class="col-8">
                                    <div class="col-12" style={{ textAlign: 'center', fontSize: 'x-large'}}>
                                        { this.props.title}
                                    </div>
                                    <div style={{background: 'white',  color: 'black'}}>
                                        { this.props.text }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid sms">
                            <div class="col-12" style={{ textAlign: 'center', fontSize: 'x-large'}}>
                                { this.props.title }
                            </div>
                            <div class="col-12" style={{ textAlign: "center" }}>
                                <div class="col-8 col-offset-2">
                                    <div style={{background: 'white',  color: 'black'}}>
                                        { this.props.text }
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" style={{ textAlign: "center" }}>
                                <div class="col-10 col-offset-1">
                                    <Image width="300" src={ this.props.image } alt="illustration" preview/>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                )
            default:
                return(
                    <div>
                        <div class="grid lgs flex align-items-center justify-content-center fadeinright animation-duration-1000">
                            <div class="sm:col-12 md:col-6 lgs" style={{ textAlign: "center" }}>
                                <div class="col-8 col-offset-4">
                                    <div class="col-12" style={{ textAlign: 'center', fontSize: 'x-large'}}>
                                        { this.props.title}
                                    </div>
                                    <div style={{background: 'white',  color: 'black'}}>
                                        { this.props.text }
                                    </div>
                                </div>
                            </div>
                            <div class="sm:col-12 md:col-6" style={{ textAlign: "center" }}>
                                <div class="col-8">
                                    <Image src={ this.props.image } alt="illustration" preview className="imgCards"/>
                                </div>
                            </div>
                        </div>
                        <div class="grid sms">
                            <div class="col-12" style={{ textAlign: "center" }}>
                                <div class="col-8 col-offset-2">
                                    <div class="col-12" style={{ textAlign: 'center', fontSize: 'x-large'}}>
                                        { this.props.title }
                                    </div>
                                    <div style={{background: 'white',  color: 'black'}}>
                                        { this.props.text }
                                    </div>
                                </div>
                                <div class="col-12" style={{ textAlign: "center" }}>
                                    <div class="col-10 col-offset-1">
                                        <Image width="300" src={ this.props.image } alt="illustration" preview/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                )
        }
        
    }
};



export default DualCard;