import React from "react";
import DualCard from "../dual-card/dualCard";
import curage1 from './assets/curage1.PNG';
import curage2 from './assets/curage2.PNG';
import curage3 from './assets/curage3.PNG';
import curage4 from './assets/curage4.PNG';
import curage5 from './assets/curage5.PNG';
import curage6 from './assets/curage6.PNG';



class Curage extends React.Component {

    p1 = <p>
        Vous êtes une collectivité territoriale ? Un industriel ? Vous gérez une communauté de communes ?
        <br/><br/>
        Suite aux intempéries ou aux sinistres, les canalisations deviennent bouchées, altérant ainsi les voiries et le paysage urbain.
        <br/><br/>
        Nous mettons nos compétences à votre service pour le pompage de vos ouvrages d'assainissement collectif.
        <br/><br/>
        Pour un débouchage de canalisations de haut niveau, nous réalisons également une inspection vidéo pour rechercher les fuites éventuelles dans votre installation d'assainissement.
    </p>

    p2 = <p>
        En plus de déboucher votre cuve à fuel, nous intervenons pour le curage de bassins grâce à nos techniques d'hydrocurage.
        <br/><br/>
        Pompes immergées, godet curage, engins chenillés, pelles mécaniques… Pour chacun des services que nous vous accordons, nous mettons à votre service un parc de véhicules complet, adapté à toute demande en curage, en pompage ou en vidange régulière.
    </p>

    p3 = <p>
        Vous êtes une collectivité territoriale ? Suite aux sinistres, aux inondations ou aux catastrophes naturelles, les égouts bouchés ne remplissent plus leur rôle d'évacuation des eaux pluviales ?
        <br/><br/>
        Les voiries subissent ainsi un engorgement à cause de l'eau stagnante ou des eaux-vannes.
        <br/><br/>
        Dans ce cas, notre entreprise d'assainissement intervient avec réactivité pour déboucher les canalisations, effectuer un curage ou un désengorgement de vos regards d'égouts et ainsi procéder à l'évacuation des eaux usées.
    </p>

    p4 = <p>
        Évier bouché ? Canalisation bouchée ? Baignoire bouchée ?
        <br/><br/>
        Rapide et efficace, notre équipe de plombiers intervient rapidement pour déboucher une canalisation, des toilettes, des lavabos ou des sanitaires, tout en respectant les délais convenus.
        <br/><br/>
        Nous intervenons pour évacuer les saletés de vos conduits en déployant notre nettoyeur haute pression, et nous débouchons vos éviers en utilisant un aspirateur performant.
        <br/><br/>
        Nous procédons également à un détartrage qui permet d'éliminer les sables ou les graviers coincés dans vos canalisations.
    </p>

    p5 = <p>
        Votre évier est bouché ? Vous constatez un mauvais écoulement d'eau dans les canalisations ? Une fuite ? Vous souhaitez bénéficier d'un débouchage de canalisations rapide ?
        <br/><br/>
        Afin de repérer avec exactitude d'où provient le problème et, ainsi, de gagner du temps, notre technique de visualisation interne de la canalisation d'un réseau permet de certifier un diagnostic précis.
        <br/><br/>
        À l'aide d'une caméra étanche utilisable dans les réseaux sanitaires, nous pouvons visualiser sur un écran de contrôle, avec un affichage instantané des distances, d'où vient avec exactitude le problème.
        <br/><br/>
        Elle nous permet d'identifier rapidement le dysfonctionnement ou les obstructions dans vos réseaux existants.
        <br/><br/>
        Ainsi, en cas d'intervention, les travaux ne sont limités qu'à une zone précise, évitant alors des coûts d'intervention supplémentaires.
    </p>

    p6 = <p>
        Cassures sur la canalisation ? Jouet d'enfant bloqué ? Sable ou gravier coincé ?
        <br/><br/>
        Équipée des dernières technologies en caméra d'inspection de canalisation, notre équipe de plombiers expérimentés réalise un test d'étanchéité de votre tuyauterie.
        <br/><br/>
        Faire une inspection caméra nous offre une visualisation claire, nous permettant de déceler la source des engorgements et localiser rapidement les bouchons.
    </p>


    componentDidMount() {
        window.scrollTo(0, 0);
    }


    render() {
        return(
            <div>
                <DualCard text={this.p1} image={curage1} title="Curage réseaux industriels et réseaux urbains" />
                <DualCard text={this.p2} image={curage2} title="Curage de fonds de bassins" order={1}/>
                <DualCard text={this.p3} image={curage3} title="Débouchage des égouts" />
                <DualCard text={this.p4} image={curage4} title="Débouchage haute pression" order={1}/>
                <DualCard text={this.p5} image={curage5} title="Notre technique d'inspection des canalisations" />
                <DualCard text={this.p6} image={curage6} title="Les avantages de notre inspection caméra" order={1}/>

            </div>
        )
    }
};



export default Curage;