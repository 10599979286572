import React from "react";
import DualCard from "../dual-card/dualCard";
import vidange1 from './assets/vidange1.PNG';
import vidange2 from './assets/vidange2.PNG';
import vidange3 from './assets/vidange3.PNG';
import vidange4 from './assets/vidange4.PNG';
import vidange5 from './assets/vidange5.PNG';
import vidange6 from './assets/vidange6.PNG';



class Vidange extends React.Component {

    p1 = <p>
        Dans le but d’éliminer les mauvaises odeurs et de garantir son bon fonctionnement, une fosse septique doit être entretenue régulièrement.
        <br/><br/>
        Un bon entretien prodigué par une entreprise d’assainissement professionnelle permet également de réduire l’accumulation de boues, afin de faciliter l’écoulement et l’évacuation des eaux usées.
        <br/><br/>
        Confiez-nous l’entretien, le curage et le nettoyage de tout type de fosse septique. Nous intervenons à votre demande en vous communiquant un devis gratuit.
    </p>

    p2 = <p>
        Faire la vidange ou l’entretien de fosse septique toutes eaux est préconisé pour rétablir le bon écoulement de vos canalisations.
        <br/><br/>
        Pour la vidange de fosse toutes eaux, notre équipe vous garantit un travail de qualité conforme aux normes de sécurité et de qualité environnementale.
        <br/><br/>
        Nous réalisons également le curage régulier de fosse pour éviter tout risque environnemental pouvant être causé par les déchets solides ou par les matières dangereuses.
    </p>

    p3 = <p>
        Des odeurs nauséabondes émanent de vos canalisations ? L’évacuation des eaux usées domestiques semble de plus en plus difficile et lente ?
        <br/><br/>
        Dans ce cas, il est grand temps de vidanger votre fosse septique ou votre fosse toutes eaux.
        <br/><br/>
        Pour particuliers et professionnels, il est recommandé d’effectuer la vidange de fosse septique tous les 4 ans en moyenne.
    </p>

    p4 = <p>
        Vos canalisations sont bouchées ? De mauvaises odeurs d’épuration entourent l’emplacement de la fosse dans votre jardin ?
        <br/><br/>
        Notre entreprise d’assainissement vous propose un bon entretien régulier de votre fosse toutes eaux permettant la neutralisation des odeurs désagréables.
        <br/><br/>
        Nous réalisons également une vidange qui permet de réduire le volume des boues et de faciliter l’écoulement et l’évacuation des eaux usées.
    </p>

    p5 = <p>
        Vous disposez d’un réseau d’assainissement collectif ? d’assainissement non collectif ?
        <br/><br/>
        Si le volume des boues excède la moitié de la fosse ou du bac à graisse, il est nécessaire de le vidanger afin d’assurer le bon écoulement des eaux-vannes vers les égouts.
        <br/><br/>
        Nous réalisons également la vidange des fosses septiques et la vidange de stations d’épuration.
        <br/><br/>
        Afin de préserver le bon fonctionnement de vos réseaux d’assainissement, la vidange de vos bacs à graisses doit être effectuée tous les 6 mois à 1 an.
    </p>

    p6 = <p>
        Un bac à graisse mal entretenu conduit les graisses à fermenter et à dégager de mauvaises odeurs.
        <br/><br/>
        Cela cause également un engorgement des réseaux et empêche d’évacuer les déchets solides ou les déchets industriels.
        <br/><br/>
        Un bon entretien régulier, réalisé par une entreprise d’assainissement professionnelle, permet à votre bac à graisse de réduire le dégorgement de vos réseaux de canalisations. Il permet également l’élimination des odeurs nauséabondes.
    </p>


    componentDidMount() {
        window.scrollTo(0, 0);
    }


    render() {
        return(
            <div>
                <DualCard text={this.p1} image={vidange1} title="Pourquoi faire une vidange ?" />
                <DualCard text={this.p2} image={vidange2} title="Vidange fosse toutes eaux" order={1}/>
                <DualCard text={this.p3} image={vidange3} title="Quand faut-il faire une vidange de fosse septique ?" />
                <DualCard text={this.p4} image={vidange4} title="Pourquoi faire une vidange de fosse toutes eaux ?" order={1}/>
                <DualCard text={this.p5} image={vidange5} title="À quelle fréquence réaliser une vidange de bac à graisse ?" />
                <DualCard text={this.p6} image={vidange6} title="Pourquoi faire un pompage de bac à graisse ?" order={1}/>

            </div>
        )
    }
};



export default Vidange;