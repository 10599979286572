import React from "react";
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

import './Landing.css';

import pro from './assets/pro.png';
import collectivite from './assets/collectivités.png';
import particulier from './assets/particulier.png';
import environnement from './assets/environnement.png';
import reactivite from './assets/reactivité.png';
import soigne from './assets/soigné.png';
import serieuse from './assets/serieuse.png';
import landing from './assets/landing.jpg'
import { Link } from "raviger";


class Landing extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div>
                <div class="grid">
                    <div class="col-6 col-offset-3" style={{textAlign: "center", fontSize: 'x-large', fontWeight: 500, color: 'black'}}>
                        Travaux d'assainissements - Curages - Débouchages - Nettoyages et vidanges <br/>
                        Interventions <b>7j/7</b> et <b>24h/24</b> dans le département de l'<b>Isère</b>, du <b>Rhône</b> et de l'<b>Ain</b>
                    </div>
                    <div class="col-12 flex align-items-center justify-content-center">
                        <img className="image" src={landing} alt="page-d'accueil" style={{ width: "100vw" }}/>
                        <div class="grid flex align-items-center justify-content-center" style={{ 
                            position: 'absolute', textAlign: "center", fontSize: 'x-large', 
                            color:'black', background: 'white', borderRadius: '3px', fontWeight: 500,
                            padding: '0px 20px 20px 20px',
                            boxShadow: '0 10px 20px #000000'
                        }}>
                            <div class="md:col-4 col-12 imageL ">
                                <img src={pro} alt="logo-professionnels" style={{ width:'80px' }} /><br/> Professionnels
                            </div>
                            <div class="md:col-4 col-12 imageL">
                                <img src={collectivite} alt="logo-collectivités" style={{ width:'80px' }} /><br/> Collectivités
                            </div>
                            <div class="md:col-4 col-12 imageL" style={{marginBottom: "15px"}}>
                                <img src={particulier} alt="logo-particulier" style={{ width:'80px' }} /><br/> Particulier
                            </div>
                            <div style={{ marginTop: '25px'}}>
                                <Link href="/contact"><Button label="Contactez Nous pour un devis gratuit !" className="p-button-warning" /></Link>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="grid" style={{textAlign: "center", fontSize: 'x-large', color:'black', fontWeight: 400, padding: "50px", background: '#ededed'}}>
                    <div class="md:col-2 col-12 md:col-offset-2">
                        <img src={environnement} alt="logo-respect-environnement" width={'75px'}/><br/> Respect de l'environnement
                    </div>
                    <div class="md:col-2 col-12">
                        <img src={reactivite} alt="logo-reactivité" width={'75px'}/><br/> Réactivité 24h/24
                    </div>
                    <div class="md:col-2 col-12">
                        <img src={soigne} alt="logo-travail-soigné" width={'75px'}/><br/> Travail soigné
                    </div>
                    <div class="md:col-2 col-12">
                        <img src={serieuse} alt="logo-entreprise-serieuse" width={'75px'}/><br/> Entreprise sérieuse et agréée
                    </div>
                    <div class="lg:col-8 lg:col-offset-2 md:col-10 md:col-offset-2" style={{ textAlign: 'justify', fontSize: 'large'}}>
                        Notre entreprise Rhône Isère Assainissement, située dans le département de l'Isère.
                        <br/><br/>
                        Nous intervenons pour <b>tous vos travaux d'assainissement</b> : curage, débouchage, nettoyage et vidange de toutes fosses.
                        <br/><br/>
                        Notre entreprise expérimentée en assainissement intervient auprès des particuliers, des industriels, des collectivités et habitats collectifs, et met également ses services à la disposition des entreprises et des administrations.
                        <br/><br/>
                        L'assainissement est une démarche visant à améliorer la situation sanitaire globale de l'environnement dans ses différents composants.
                        <br/><br/>
                        Il comprend la collecte, le traitement et l'évacuation des déchets liquides et des déchets solides.
                        <br/><br/>
                        Notre entreprise, <b>disponible 7j/7 et 24h/24</b>, met un service d'astreinte et d'urgence à votre disposition afin de vous assurer un service en continu.
                        Nous avons reçu la <b>formation à la sécurité des personnes des entreprises extérieures</b>, délivrée par le centre de formation Ifaip Interfora.
                    </div>
                </div>
                <p style={{ textAlign: 'center', fontSize: 'xx-large'}}> Votre satisfaction, notre priorité !</p><br/>
                <div class="grid">
                    <div class="md:col-4 sm:col-12">
                        <div class="card white col-8 col-offset-2">
                           <div class="card-content" style={{ textAlign: 'center' }}>
                                Monic V.
                                <p style={{ textAlign: 'center' }}>
                                    <i class="pi pi-star-fill"></i>
                                    <i class="pi pi-star-fill"></i>
                                    <i class="pi pi-star-fill"></i>
                                    <i class="pi pi-star-fill"></i>
                                    <i class="pi pi-star-fill"></i><br/>
                                    "Super prestation, disponibilite, compétence, sérieux, réponses à toutes questions sur la situation.<br/>
                                    Encore merci. Je recommande vivement."
                                </p>
                            </div>
                            <p class="grey-text text-darken-3 lighten-3" style={{ textAlign: 'center', opacity: 0.5 }}>Avis récolté sur Google</p> 
                        </div>
                    </div>
                    <div class="md:col-4 sm:col-12">
                        <div class="card white col-8 col-offset-2">
                        <div class="card-content" style={{ textAlign: 'center' }}>
                            Eric F.
                            <p style={{ textAlign: 'center' }}>
                                <i class="pi pi-star-fill"></i>
                                <i class="pi pi-star-fill"></i>
                                <i class="pi pi-star-fill"></i>
                                <i class="pi pi-star-fill"></i>
                                <i class="pi pi-star-fill"></i><br/>
                                "Une intervention rapide, efficace, très professionnelle et sympathique en plus, et tout ça à un coût raisonnable. [...] Merci !"
                            </p>
                        </div>
                        <p class="grey-text text-darken-3 lighten-3" style={{ textAlign: 'center', opacity: 0.5 }}>Avis récolté sur Google</p>
                        </div>
                    </div>
                    <div class="md:col-4 sm:col-12">
                        <div class="card white col-8 col-offset-2">
                        <div class="card-content" style={{ textAlign: 'center' }}>
                            Stephane V.
                            <p style={{ textAlign: 'center' }}>
                                <i class="pi pi-star-fill"></i>
                                <i class="pi pi-star-fill"></i>
                                <i class="pi pi-star-fill"></i>
                                <i class="pi pi-star-fill"></i>
                                <i class="pi pi-star-fill"></i><br/>
                                "Alors tous d'abord un grand bravo, personnel efficace et super sympathique  à l'écoute. [...] je vous recommande societe digne de confiance."
                            </p>
                        </div>
                        <p class="grey-text text-darken-3 lighten-3" style={{ textAlign: 'center', opacity: 0.5 }}>Avis récolté sur Google</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};



export default Landing;