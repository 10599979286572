import React from "react";

import './Footer.css';
import carte from './assets/carte-isère-assainissement-2.png';
import logo from '../navbar/assets/logo-isere-assainissement.png';
import { Link } from 'raviger';



class Footer extends React.Component {

    render() {
        return(
            <div class="grid  flex align-items-center justify-content-center" style={{ width: '100vw', background: "#f9deae"}}>
                <div class="md:col-4 sm:col-12" style={{textAlign: 'center'}}>
                    Rhône Isère Assainissement <br/>
                    1105 route de Chapèze
                    38300 Saint-Savin<br/>
                    25 rue André Chénier 
                    69120 Vaulx en Velin <br/>
                    <i className="material-icons">local_phone</i> <a href="tel:0474802217" style={{color: "#f7931e" }}>04 74 80 22 17</a> <br />
                    <i className="material-icons">phone_android</i> <a href="tel:0626662794" style={{color: "#f7931e" }}>06 26 66 27 94</a> <br/>
                </div>
                <div class="md:col-4 sm:col-12" style={{textAlign: 'center'}}><Link href="/" ><img src={logo} alt="Logo Rhône Isère assainissement" height={100} /></Link></div>
                <div class="md:col-4 sm:col-12" style={{textAlign: 'center'}}><img src={carte} alt="carte isère assainissement" height={200} /></div>
                <span> © 2023 - Rhône Isère Assainissement</span>
            </div>   
        )
    }
};



export default Footer;