import React from "react";
import DualCard from "../dual-card/dualCard";
import siteindu1 from './assets/siteindu1.PNG';
import siteindu2 from './assets/siteindu2.PNG';
import siteindu3 from './assets/siteindu3.PNG';


class SiteIndustriel extends React.Component {

    p1 = <p>
        La stagnation des produits occasionne des dépôts multiples qui limitent la capacité et génèrent des pollutions. Par ailleurs, les vérifications règlementaires, ainsi que les opérations de réparation, nécessitent un nettoyage et un dégazage prérequis.
        <br/><br/>
        Pour des circonstances optimales de production, nous intervenons sur toutes vos installations de stockage fixes ou roulantes : cuves à bitume (émulsion bitume), cuves à fioul, stockage et transport de produits hydrocarburés, épandeuses, etc.
    </p>

    p2 = <p>
        Notre expérience dans le domaine des réseaux d'assainissement industriel nous permet d'intervenir pour tout type d'opération de pompage et de curage des réseaux industriels, et notamment pour le nettoyage et la dépollution de bacs industriels, bitume, cuves industrielles, machines industrielles.
        <br/><br/>
        Nos moyens humains et matériels respectueux de l'environnement nous permettent de pourvoir à tous vos besoins avec rapidité et en toute sécurité. Nos véhicules sont agréés pour le transport des déchets.
    </p>

    p3 = <p>
        Vous avez des besoins réguliers pour répondre aux normes imposées par l'État en ce qui concerne l'assainissement industriel ? Nous vous proposons des contrats d'assainissement industriel adaptés à vos besoins, quelles que soient les spécificités de votre industrie et de votre activité. En effet, les rejets industriels impliquent une règlementation stricte, que nous sommes en mesure d'appliquer.
        <br/><br/>
        Contactez-nous dès aujourd'hui pour toute demande de devis gratuit pour tous travaux d'assainissement de site industriel.
    </p>


    componentDidMount() {
        window.scrollTo(0, 0);
    }


    render() {
        return(
            <div>
                <DualCard text={this.p1} image={siteindu1} title="Nettoyage de cuves de bitume, silos…" />
                <DualCard text={this.p2} image={siteindu2} title="Nettoyage de réseaux industriels" order={1}/>
                <DualCard text={this.p3} image={siteindu3} title="Notre entreprise pour votre assainissement industriel" />
            </div>
        )
    }
};



export default SiteIndustriel;