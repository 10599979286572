import React from "react";

import './Navbar.css';
import logo from './assets/logo-isere-assainissement.png';
import { Link } from 'raviger';




class Navbar extends React.Component {

    toggleNavbar() {
        let x = document.getElementById("navbar");
        if(x.classList.contains("topnav")&&!x.classList.contains("responsive")){
          x.classList.add("responsive");
        }else{
          x.classList.add("topnav");
          x.classList.remove("responsive");
        }
      }
    
      componentDidMount() {
        window.onscroll = () => this.stickyBar() ;
      }
    
      stickyBar() {
        // Get the navbar
        let navbar = document.getElementById("navbar");
    
        // Get the offset position of the navbar
        let sticky = navbar.offsetTop;
    
        // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
        if (window.pageYOffset >= sticky+30) {
          navbar.classList.add("stickyBar")
        } else {
          navbar.classList.remove("stickyBar");
        }
      }


    render() {
        return(
            <div className="nav">
                <div id="logo">
                <Link href="/" ><img id="img_logo" src={logo} alt="logo" /></Link><br/>
                    <span className="valign-wrapper">
                      <i className="material-icons">phone_android</i> : <a href="tel:0626662794" style={{color: "#f7931e" }}>06 26 66 27 94</a> <br/>
                      <i className="material-icons">local_phone</i> : <a href="tel:0474802217" style={{color: "#f7931e" }}>04 74 80 22 17</a></span><br/>
                    <span className="valign-wrapper"><i className="material-icons">email</i><a style={{ color: '#c6312b'}} href="mailto:contact@ri-assainissement.fr"> : contact@ri-assainissement.fr</a></span>
                </div>   
                <div id="navbar" className="topnav">
                    <Link onClick={() => this.toggleNavbar()} href="/assainissement" ><div className="menu_option">Assainissement</div></Link>
                    <Link onClick={() => this.toggleNavbar()} href="/pompage" ><div className="menu_option" href="/pompage">Pompage</div></Link>
                    <Link onClick={() => this.toggleNavbar()} href="/vidange" ><div className="menu_option" href="/vidange">Vidange</div></Link>
                    <Link onClick={() => this.toggleNavbar()} href="/curage" ><div className="menu_option" href="/curage">Curage</div></Link>
                    <Link onClick={() => this.toggleNavbar()} href="/nettoyage" ><div className="menu_option" href="/nettoyage">Nettoyage</div></Link>
                    <Link onClick={() => this.toggleNavbar()} href="/site-industriel" ><div className="menu_option" href="/site-industriel">Site industriel</div></Link>
                    <Link onClick={() => this.toggleNavbar()} href="/contact" ><div className="menu_option" href="/contact">Contact</div></Link>
                    <div className="icon menu_option" onClick={() => this.toggleNavbar()}><i class="material-icons">menu</i></div>
                </div>
            </div>
        )
    }
};



export default Navbar;