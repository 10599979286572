import Assainissement from "./components/assainissement/asainissement";
import Contact from "./components/contact/contact";
import Curage from "./components/curage/curage";
import Landing from "./components/landing/Landing";
import Nettoyage from "./components/nettoyage/nettoyage";
import Pompage from "./components/pompage/pompage";
import SiteIndustriel from "./components/site-industriel/siteIndustriel";
import Vidange from "./components/vidange/vidange";


const router = {
  '/': () => <Landing />,
  '/assainissement': () => <Assainissement />,
  '/pompage': () => <Pompage />,
  '/vidange': () => <Vidange />,
  '/curage': () => <Curage />,
  '/nettoyage': () => <Nettoyage />,
  '/site-industriel': () => <SiteIndustriel />,
  '/contact': () => <Contact />
};

export default router;