import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar/Navbar';
import router from './router';
import { useRoutes } from 'raviger';
import Footer from './components/footer/Footer';

function App() {
  const routes = useRoutes(router);
  return (
    <div>
        <Navbar />
        { routes }
        <Footer />
    </div>
  );
}

export default App;
