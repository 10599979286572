import React from "react";
import DualCard from "../dual-card/dualCard";
import pompage1 from './assets/pompage1.PNG';
import pompage2 from './assets/pompage2.PNG';
import pompage3 from './assets/pompage3.PNG';
import pompage4 from './assets/pompage4.PNG';
import pompage5 from './assets/pompage5.PNG';
import pompage6 from './assets/pompage6.PNG';
import pompage7 from './assets/pompage7.PNG';

class Pompage extends React.Component {

    p1 = <p>
        Le volume des boues gêne le bon fonctionnement de votre fosse septique ?
        <br/><br/>
        Expérimentée en pompage de fosse septique, notre entreprise d’assainissement prend en charge la vidange de tout type de fosses.
        <br/><br/>
        Nous vous assistons avec réactivité pour assurer le bon entretien, le nettoyage des cuves, ainsi que le curage de fosses septiques.
    </p>

    p2 = <p>
        Des bacs à graisse mal entretenus entraînent la prolifération de bactéries, ainsi que des odeurs nauséabondes.
        <br/><br/>
        Dans le but d’effectuer la vidange de bac à graisse durablement, nous exploitons du matériel professionnel à la pointe de la technologie.
        <br/><br/>
        Nous garantissons votre confort et votre sécurité, en éliminant les matières dangereuses dans le respect de l’environnement.
    </p>

    p3 = <p>
        Les séparateurs d’hydrocarbures permettent de séparer les liquides des solides, pour mieux évacuer les eaux usées.
        <br/><br/>
        Une fois qu’il reçoit l’écoulement des eaux, le débourbeur procède à la décantation pour piéger les boues, les sédiments lourds. Le déshuileur, quant à lui, permet aux huiles de rester en surface.
        <br/><br/>
        Pour garantir le bon fonctionnement de votre séparateur d’hydrocarbures, nous déployons notre équipe de techniciens pour procéder au nettoyage industriel de ce dernier.
    </p>

    p4 = <p>
        Un entretien régulier de votre cuve enterrée représente une solution respectueuse pour l’environnement.
        <br/><br/>
        Une cuve à fioul mal entretenue conduit à un risque de pollution élevé. Tout en œuvrant pour la protection de l’environnement, nous intervenons pour le pompage, le curage et le nettoyage des cuves à fioul.
        <br/><br/>
        Nous participons à la dépollution de votre site, en vous prodiguant un nettoyage de cuve à fioul pour l’élimination des matières dangereuses.
    </p>

    p5 = <p>
        Vous êtes responsable d’une communauté de communes ? Vous représentez un syndicat intercommunal ? Vous cherchez une entreprise d’assainissement qualifiée ?
        <br/><br/>
        Notre entreprise Rhône Isère Assainissement tient compte de vos exigences réglementaires et de vos besoins pour le traitement et le transport de vos eaux usées. Nous disposons de tout l’équipement et des véhicules nécessaires pour réaliser le transport des eaux usées de façon efficace et rapide.
    </p>

    p6 = <p>
        Les eaux industrielles sont des eaux rejetées par les industries. Elles contiennent souvent des solvants, des métaux lourds et des hydrocarbures.
        <br/><br/>
        Notre entreprise d’assainissement est habilitée pour réaliser ce type de transport d’eaux industrielles en toute sécurité, grâce à des véhicules adaptés.
    </p>

    p7 = <p>
        Les eaux domestiques proviennent de vos différents usages domestiques quotidiens. La production des eaux ménagères est souvent porteuse de pollution organique.
        <br/><br/>
        Pour remédier à cela, nous intervenons pour traiter les eaux grises évacuées de vos habitations, avant qu’elles ne soient rejetées dans le milieu naturel ou dans les égouts.
        <br/><br/>
        Notre service d’assainissement vous propose ainsi ses services pour assurer le transport des eaux usées domestiques issues des zones d’habitation vers un centre de traitement agréé.
        <br/><br/>
        Nos techniques de pompage participent à l’élimination des matières organiques polluantes.
    </p>

    componentDidMount() {
        window.scrollTo(0, 0);
    }


    render() {
        return(
            <div>
                <DualCard text={this.p1} image={pompage1} title="Pompage et vidange de fosse septique" />
                <DualCard text={this.p2} image={pompage2} title="Pompage bac à graisse" order={1}/>
                <DualCard text={this.p3} image={pompage3} title="Nettoyage et entretien séparateur hydrocarbures" />
                <DualCard text={this.p4} image={pompage4} title="Pompage de cuve à fioul" order={1}/>
                <DualCard text={this.p5} image={pompage5} title="Collecte et transport des déchets liquides" />
                <DualCard text={this.p6} image={pompage6} title="Transport des eaux industrielles" order={1}/>
                <DualCard text={this.p7} image={pompage7} title="Transport des eaux domestiques" />
            </div>
        )
    }
};



export default Pompage;